import React, {useState, useRef} from "react";
import withStore from "@/hocs/withStore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import isEmpty from "is-empty";
// import {styled} from '@mui/material/styles';
// import Rating from "@mui/material/Rating";
import PriceMinimum from "@/Pages/All/PriceMinimum";
import LinkLoad from "@/Layouts/LinkLoad";
import {declOfNum, isDesktop} from "@/Helpers/Function";
import LazyLoad from 'react-lazyload';
import Svg from "@/Pages/All/Svg";
import {useSwipeable} from "react-swipeable";

const FavouritesHeart = () => (
    <Svg id={`favouritesHeart`} className={`svg__favouritesHeart`} />
);

const FavouritesHeartActive = () => (
    <Svg id={`favouritesHeartActive`} className={`svg__favouritesHeartActive`} />
);

// const StyledRating = styled(Rating)({
//     '& .MuiRating-iconFilled': {
//         color: '#006856',
//     },
//     '& .MuiRating-iconHover': {
//         color: '#006856',
//     },
// });

function handleItemClick(filterStore) {
    filterStore.scrollPositionChange(window.pageYOffset);
}

function CardAll({index = 0, ...props}) {
    const {el = {}, className = '', config = {}, stores = {}, type = ''} = props;
    const {favouritesStore = {}, filterStore = {}, allStore = {}} = stores;
    const {imgDetail = '', ratingAVG = null} = el;
    const {defaultImage = {}} = config;

    // const media = imgDetail.split(';') ?? [];
    const media = imgDetail.split(";").map(url => url.replace(/\.png/g, ".webp"));
    // const media = el.imgDetail.split(';').filter((el) => !el.includes('mp4')) ?? [];

    let cardClassName = className ?? '';
    let imgWebp = '', img = '';
    if (!isEmpty(el.img)) {
        img = el.img;
        if (el.img.includes('png')) {
            imgWebp = el.img.replace('png', 'webp');
        }
        if (el.img.includes('jpg')) {
            imgWebp = el.img.replace('jpg', 'webp');
        }
        if (el.img.includes('jpeg')) {
            imgWebp = el.img.replace('jpeg', 'webp');
        }
    } else {
        img = defaultImage.previewPath;
        imgWebp = defaultImage.previewPathWebp;
    }

    // let ratingAVG = 0;
    // if (!isEmpty(el.ratingAVG)) {
    //     ratingAVG = Number(el.ratingAVG)
    // }

    const cardPrice = (el) => {
        if (el.card_price !== el.price) {
            return <div className={`price`}>
                <span className={`price__current`}>{Number(el.card_price).toLocaleString('ru')} ₽</span>
                <div className={`price__old_discount`}>
                    <span className={`price__old`}>{Number(el.price).toLocaleString('ru')} ₽</span>
                    {el.card_discount !== null && (
                        <div className={`discountMarker`}
                             style={{
                                 color: `${el.marker_discount_color}`,
                                 backgroundColor: `${el.marker_discount_bg_color}`
                             }}
                        >
                            {`-${el.card_discount}%`}
                        </div>
                    )}
                </div>
            </div>
        } else if (el.card_price === null) {
            return <div className={`price`}>
                <span className={`price__current`}>Предзаказ</span>
            </div>
        } else {
            return <div className={`price`}>
                <span className={`price__current`}>{Number(el.card_price).toLocaleString('ru')} ₽</span>
            </div>
        }
    };

    const cardButtons = ({...props}) => {
        const {stores, el} = props;
        const {productDetailStore = {}, quickViewStore = {}, profileStore = {}, basketStore = {}} = stores;

        // Если продукт не доступен
        if (el.status === null) {
            return <div className={`btn btn__white btn__disabled btn__out-stock`}>Не доступно</div>
        }

        // Если нет продукта
        if (el.card_price === null) {
            return <>
                <button className={`btn btn__addWaitList`} onClick={() => profileStore.getSizeWaitList(el.id)}>
                    В лист ожидания
                </button>
                {isDesktop() && (
                    <button className={`btn btn__quick-view`} onClick={() => quickViewStore.openModal(el.article)}>
                        <Svg id={`quick-view`} className={`svg__quickView`} />
                    </button>
                )}
            </>
        } else {
            return <>
                {basketStore.basketProducts.some((ls) => ls.idProduct === el.id) ?
                    <LinkLoad href={`/basket`}
                              method={`post`}
                              as={'button'}
                              data={{
                                  paymentMethod: basketStore.valuePaymentMethod,
                                  promoCodeValue: basketStore.promoCodeValues,
                                  certificateData: basketStore.certificateData,
                                  bonuses: basketStore.subtractBonuses,
                              }}
                              className={`btn btn__addCartCatalog`}>
                        В корзине
                    </LinkLoad>
                    :
                    <button className={`btn btn__addCartCatalog`}
                        onClick={async () => {
                            await productDetailStore.addBasketCard(el.id, el.article, el.minimumPrice !== 0 ? el.minimumPrice : el.card_price)
                        }}
                    >
                            В корзину
                    </button>
                }
                {isDesktop() && (
                    <button className={`btn btn__quick-view`} onClick={() => quickViewStore.openModal(el.article)}>
                        <Svg id={`quick-view`} className={`svg__quickView`} />
                    </button>
                )}
            </>
        }
    };

    // const YandexContainer = () => {
    //     const style = {
    //         display: 'flex',
    //         flexDirection: 'column',
    //         // alignItems: 'center',
    //         gap: '8px',
    //         marginBottom: '12px',
    //     };
    //
    //     let bnplSize = 'l', cashbackSize = 'm';
    //     if (isMobile()) {
    //         bnplSize = 'm';
    //         cashbackSize = 's';
    //     }
    //
    //     return <div style={style}>
    //         <yandex-pay-badge
    //             type={`bnpl`}
    //             amount={el.price_split_full}
    //             size={bnplSize}
    //             variant={`simple`}
    //             theme={`light`}
    //             merchant-id={`a8094f84-fddd-47df-a6af-28faabbb9e6c`}
    //         />
    //         <yandex-pay-badge
    //             type={`cashback`}
    //             amount={el.price_split_full}
    //             size={cashbackSize}
    //             variant={`default`}
    //             theme={`light`}
    //             merchant-id={`a8094f84-fddd-47df-a6af-28faabbb9e6c`}
    //         />
    //     </div>
    // }

    const cardBody = () => {
        return <>
            {el.status !== null && (
                <>
                    <div className={`item-markers`}>
                        {el.sticker && (
                            <img src={el.sticker} alt={el.sticker} className={`sticker`} />
                        )}
                        {/*{newCard(el)}*/}
                        {/*{hitCard(el)}*/}
                    </div>
                    <div className={`item-heart`}>
                        <FormControlLabel
                            control={<Checkbox
                                onClick={(e) => favouritesStore.favouritesChange(e, el)}
                                checked={favouritesStore.favouritesData.some((som) => Number(som.id) === Number(el.id))}
                                icon={<FavouritesHeart />}
                                checkedIcon={<FavouritesHeartActive />}
                                name={`favoritesHeart`} />}
                        />
                    </div>
                </>
            )}
            <LinkLoad href={`/catalog/product/${el.article}`}
                      title={el.h1 + ' ' + el.article}
                      onClick={() => handleItemClick(filterStore)}
                      className={`image`}
            >
                {type === 'catalog' ?
                    <Slider media={media} alt={el.h1 + ' ' + el.article} />
                    :
                    <div className={`box-img`}>
                        <picture>
                            <source className={`increase`} type={`image/webp`} srcSet={imgWebp} />
                            <img
                                className={`increase`}
                                width={`290`}
                                height={`290`}
                                loading={`lazy`}
                                src={img}
                                alt={el.h1 + ' ' + el.article}
                                title={el.h1 + ' ' + el.article}
                            />
                        </picture>
                    </div>
                }
            </LinkLoad>
            <div className={`cardCatalog__item_content`}>
                <PriceMinimum {...props} />
                {cardPrice(el)}
                {/*<YandexContainer />*/}
                <LinkLoad
                    href={`/catalog/product/${el.article}`}
                    className={`cardCatalogName truncate`}
                    onClick={() => handleItemClick(filterStore)}
                >
                    {el.h1}
                </LinkLoad>
                {el.ratingCount !== null ?
                    <div className={`cardCatalog__rating`}>
                        <Svg id={`star`} className={`svg__cardCatalogStar`} />
                        <div className={`cardCatalog__averageRating`}>{ratingAVG}</div>
                        <div className={`cardCatalog__ratingDot`}></div>
                        <div className={`cardCatalog__countReviews`}>
                            {el.ratingCount} {declOfNum(el.ratingCount, [' отзыв', ' отзыва', ' отзывов'])}
                        </div>
                    </div>
                    :
                    <div className={`cardCatalog__rating`}>
                        <Svg id={`star`} className={`svg__cardCatalogStarNotRating`} />
                        <p className={`cardCatalog__notRating`}>Нет оценок</p>
                    </div>
                }
            </div>
        </>
    };

    return (
        <div className={`cardCatalog ${cardClassName}`}>
            <div className={`cardCatalog__item`}>
                {allStore.interactionFlag ? (
                    <>
                        {cardBody()}
                        <div className={`cardCatalog__item_buttons`}>
                            {cardButtons(props)}
                        </div>
                    </>
                ) : (
                    <LazyLoad throttle={200} offset={1700}>
                        {cardBody()}
                        <div className={`cardCatalog__item_buttons`}>
                            {cardButtons(props)}
                        </div>
                    </LazyLoad>
                )}
            </div>
        </div>
    );
}

const Slider = ({media = [], alt = ''}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const videoRef = useRef(null);

    // media = [
    //     'https://ir-3.ozone.ru/s3/multimedia-1-k/wc500/7372725464.jpg',
    //     'https://ir-3.ozone.ru/s3/multimedia-1-i/wc500/7337178882.jpg',
    //     'https://ir-3.ozone.ru/s3/multimedia-1-r/wc500/7372989819.jpg',
    //     'https://ir-3.ozone.ru/s3/multimedia-1-q/wc500/7269154694.jpg',
    //     'https://ir-3.ozone.ru/s3/multimedia-1-o/wc500/7425332088.jpg',
    // ];

    // Функция для смены изображения/видео
    const changeMedia = (index) => {
        setActiveIndex(index);
        if (media[index].endsWith(".mp4") && videoRef.current) {
            videoRef.current.load(); // Обновляем видео
        }
    };

    // Обработчики свайпа
    const handlers = useSwipeable({
        onSwipedLeft: () => setActiveIndex((prev) => (prev + 1) % media.length),
        onSwipedRight: () => setActiveIndex((prev) => (prev - 1 + media.length) % media.length),
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <div {...handlers} className={`cardCatalog__mediaBox`} onMouseOut={() => setActiveIndex(0)}>
            {media[activeIndex].endsWith(".mp4") ? (
                <video ref={videoRef}
                       playsInline
                       src={media[activeIndex]}
                       className={`cardCatalog__video w-full h-full object-cover rounded-lg`}
                       autoPlay
                       loop
                       muted
                />
            ) : (
                <picture className={`cardCatalog__picture`}>
                    <img loading={`eager`}
                         fetchPriority={`high`}
                         src={media[activeIndex]}
                        // srcSet={media[activeIndex] + ' 2x'}
                         alt={alt}
                         className={`cardCatalog__image w-full h-full object-cover rounded-lg transition-opacity duration-300`}
                    />
                </picture>
            )}
            <div className={`cardCatalog__hiddenBox`}>
                {media.map((_, index) => (
                    <div key={index} className={`cardCatalog__hiddenBoxItem`} onMouseEnter={() => changeMedia(index)} />
                ))}
            </div>
            <div className={`cardCatalog__dots`}>
                {media.map((_, index) => (
                    <div key={index} className={`cardCatalog__dotsItem${index === activeIndex ? ' active' : ''}`} />
                ))}
            </div>
        </div>
    );
};

export default withStore(CardAll);
